import FileSaver from 'file-saver';
import {defineConfig} from '../config'
import { authHeader } from '../helpers';
import { utilsService } from './utils'
const {handleResponse} = utilsService

export const auditService = {
    exportFile,
    importFile,
    create,
    update,
    deleteAudit,
    getAll,
    getPaginatedByRangeDate,
    getDistinctByField,
};

const {apiUrl, apiVer} = defineConfig.AUDIT_SERVICE;

function getApiPath(){
  const version = (apiVer !== null ? ("/" + apiVer) : "");
  return (apiUrl + version);
}

function getAll() {
  const url = getApiPath() + "/audit/all";

  const requestOptions = {
    method: "GET",
    headers: authHeader("application/json"),
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function getPaginatedByRangeDate(dateStart, dateEnd, page = "0", perPage = "9999", sortType = "ASC") {
  const url = getApiPath() + `/audit/byRangeDate/page/${page}/perPage/${perPage}/sort/${sortType}/?dateStart=${dateStart}&dateEnd=${dateEnd}`;

  const requestOptions = {
    method: "GET",
    headers: authHeader("application/json"),
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function getDistinctByField(field) {
  const url = getApiPath() + `/audit/distinct/${field}`;

  const requestOptions = {
    method: "GET",
    headers: authHeader("application/json"),
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function exportFile(startDate, endDate) {
  const url = getApiPath() + `/audit/exportExcel?dateStart=${startDate}&dateEnd=${endDate}`;

  const requestOptions = {
    method: "GET",
    headers: authHeader("application/json"),
  };

  return fetch(
    url,
    requestOptions
  )
  .then(response => {
    console.log("scarico....", url)
    return response.blob()
  })
  .then(function(blob) {
    FileSaver.saveAs(blob, `export${startDate}${endDate}.xlsx`)
    return true;
  })
}

function importFile(file) {
  const url = getApiPath() + "/audit/upload";
  const formData = new FormData();
  formData.append("file", file);

  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: formData
  };

  return fetch(
    url,
    requestOptions
  )
  .then(handleResponse)
  .then(res => {
    return res;
  });
}

function create(obj) {
  const url = getApiPath() + "/audit/save";

  const requestOptions = {
    method: "POST",
    headers: authHeader("application/json"),
    body: JSON.stringify(obj)
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function update(id, obj) {
  const url = getApiPath() + `/audit/update?auditId=${id}`;

  const requestOptions = {
    method: "PUT",
    headers: authHeader("application/json"),
    body: JSON.stringify(obj)
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function deleteAudit(id) {
  const url = getApiPath() + "/audit/delete?auditId=" + id;

  const requestOptions = {
    method: "DELETE",
    headers: authHeader("application/json"),
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

