export const gatewayConfig = {
  ASSET_SERVICE,
  SYSTEMS_SERVICE,
  COMPANIES_SERVICE,
  GEO_SERVICE,
  AUDIT_SERVICE,
  USER_SERVICE,
  CREATIVITY_SERVICE,
  CAMPAIGN_SERVICE,
  PLANNER_SERVICE,
  CATALOG_SERVICE,
  PRODUCT_SERVICE,
  ESTIMATE_SERVICE,
  CIRCUITS_SERVICE,
  SHARE_SERVICE,
}

function ASSET_SERVICE(ambience) {
  let apiUrl, apiVer
  switch (ambience) {
    case "dev":
      apiUrl = "https://kinasset.codesour.dev/"
      apiVer = "v1";
      break;
    case "stage":
      apiUrl = "https://kinasset.codesour.dev/"
      apiVer = "v1";
      break;
    case "prod":
      apiUrl = "https://api-alessiplatform.codesour.dev/"
      apiVer = "v1";
      break;
    default:
      return null;
  }

  return {
    apiUrl,
    apiVer
  }
}

function GEO_SERVICE(ambience) {
  let apiUrl, apiVer
  switch (ambience) {
    case "dev":
      apiUrl = "https://api-alessiplatform.codesour.dev"
      apiVer = "v1";
      break;
    case "stage":
      apiUrl = "https://api-alessiplatform-stage.codesour.dev"
      apiVer = "v1";
      break;
    case "prod":
      apiUrl = "https://api-alessiplatform.codesour.dev"
      apiVer = "v1";
      break;
    default:
      return null;
  }

  return {
    apiUrl,
    apiVer
  }
}

function AUDIT_SERVICE(ambience) {
  let apiUrl, apiVer
  switch (ambience) {
    case "dev":
      // apiUrl = "http://51.91.104.29:8094"
      apiUrl = "https://api-alessiplatform.codesour.dev"
      apiVer = "v1";
      break;
    case "stage":
      apiUrl = "https://api-alessiplatform-stage.codesour.dev"
      apiVer = "v1";
      break;
    case "prod":
      apiUrl = "https://api-alessiplatform.codesour.dev"
      apiVer = "v1";
      break;
    default:
      return null;
  }

  return {
    apiUrl,
    apiVer
  }
}

function SYSTEMS_SERVICE(ambience) {
  let apiUrl, apiVer
  switch (ambience) {
    case "dev":
      // apiUrl = "https://f65d-151-12-79-86.eu.ngrok.io"
      // apiUrl = "https://api-alessiplatform.codesour.dev"

      // apiUrl = "http://192.168.1.7:8093"
      apiUrl = "https://api-alessiplatform.codesour.dev"
      apiVer = "v1";
      break;
    case "stage":
      apiUrl = "https://api-alessiplatform-stage.codesour.dev"
      apiVer = "v1";
      break;
    case "prod":
      apiUrl = "https://api-alessiplatform.codesour.dev"
      apiVer = "v1";
      break;
    default:
      return null;
  }

  return {
    apiUrl,
    apiVer
  }
}

function CIRCUITS_SERVICE(ambience) {
  let apiUrl, apiVer
  switch (ambience) {
    case "dev":
      // apiUrl = "https://f65d-151-12-79-86.eu.ngrok.io"
      // apiUrl = "https://api-alessiplatform.codesour.dev"

      // apiUrl = "http://192.168.1.7:8093"
      apiUrl = "https://api-alessiplatform.codesour.dev"
      apiVer = "v1";
      break;
    case "stage":
      apiUrl = "https://api-alessiplatform-stage.codesour.dev"
      apiVer = "v1";
      break;
    case "prod":
      apiUrl = "https://api-alessiplatform.codesour.dev"
      apiVer = "v1";
      break;
    default:
      return null;
  }

  return {
    apiUrl,
    apiVer
  }
}

function COMPANIES_SERVICE(ambience) {
  let apiUrl, apiVer
  switch (ambience) {
    case "dev":
      apiUrl = "https://api-alessiplatform.codesour.dev/company"
      apiVer = "v1";
      break;
    case "stage":
      apiUrl = "https://api-alessiplatform-stage.codesour.dev/company"
      apiVer = "v1";
      break;
    case "prod":
      apiUrl = "https://api-alessiplatform.codesour.dev/company"
      apiVer = "v1";
      break;
    default:
      return null;
  }

  return {
    apiUrl,
    apiVer
  }
}

function CAMPAIGN_SERVICE(ambience) {
  let apiUrl, apiVer
  switch (ambience) {
    case "dev":
      apiUrl = "https://api-alessiplatform.codesour.dev/"
      apiVer = "v1";
      break;
    case "stage":
      apiUrl = "https://api-alessiplatform-stage.codesour.dev/"
      apiVer = "v1";
      break;
    case "prod":
      apiUrl = "https://api-alessiplatform.codesour.dev/"
      apiVer = "v1";
      break;
    default:
      return null;
  }

  return {
    apiUrl,
    apiVer
  }
}

function PLANNER_SERVICE(ambience) {
  let apiUrl, apiVer
  switch (ambience) {
    case "dev":
      apiUrl = "https://api-alessiplatform.codesour.dev/"
      apiVer = "v1";
      break;
    case "stage":
      apiUrl = "https://api-alessiplatform-stage.codesour.dev/"
      apiVer = "v1";
      break;
    case "prod":
      apiUrl = "https://api-alessiplatform.codesour.dev/"
      apiVer = "v1";
      break;
    default:
      return null;
  }

  return {
    apiUrl,
    apiVer
  }
}

function USER_SERVICE(ambience) {
  let apiUrl
  switch (ambience) {
    case "dev":
      apiUrl = "https://api-alessiplatform.codesour.dev"
      break;
    case "stage":
      apiUrl = "https://api-alessiplatform-stage.codesour.dev"
      break;
    case "prod":
      apiUrl = "https://api-alessiplatform.codesour.dev"
      break;
    default:
      return null;
  }

  return {
    apiUrl,
  }
}

function CREATIVITY_SERVICE(ambience) {
  let apiUrl, apiVer

  switch (ambience) {
    case "dev":
      //apiUrl = "http://develop.codesour.tech:8094"
      apiUrl = "https://api-alessiplatform.codesour.dev"
      //apiUrl = "https://api-alessiplatform.codesour.dev"
      apiVer = "v1";
      break;
    case "stage":
      apiUrl = "https://api-alessiplatform-stage.codesour.dev"
      apiVer = "v1";
      break;
    case "prod":
      apiUrl = "https://api-alessiplatform.codesour.dev"
      apiVer = "v1";
      break;
    default:
      return null;
  }

  return {
    apiUrl,
    apiVer
  }
}

function CATALOG_SERVICE(ambience) {
  let apiUrl, apiVer

  switch (ambience) {
    case "dev":
      apiUrl = "https://api-alessiplatform.codesour.dev/estimate/v1/catalog";
      break;
    case "stage":
      apiUrl = "https://api-alessiplatform-stage.codesour.dev/estimate/v1/catalog"
      break;
    case "prod":
      apiUrl = "https://api-alessiplatform.codesour.dev/estimate/v1/catalog"
      break;
    default:
      return null;
  }

  return {
    apiUrl,
    apiVer
  }
}

function PRODUCT_SERVICE(ambience) {
  let apiUrl, apiVer

  switch (ambience) {
    case "dev":
      apiUrl = "https://api-alessiplatform.codesour.dev";
      break;
    case "stage":
      apiUrl = "https://api-alessiplatform-stage.codesour.dev"
      break;
    case "prod":
      apiUrl = "https://api-alessiplatform.codesour.dev"
      break;
    default:
      return null;
  }

  return {
    apiUrl,
    apiVer
  }
}

function ESTIMATE_SERVICE(ambience) {
  let apiUrl, apiVer

  switch (ambience) {
    case "dev":
      apiUrl = "https://api-alessiplatform.codesour.dev/estimate/v1/";
      break;
    case "stage":
      apiUrl = "https://api-alessiplatform-stage.codesour.dev/estimate/v1/"
      break;
    case "prod":
      apiUrl = "https://api-alessiplatform.codesour.dev/estimate/v1/"
      break;
    default:
      return null;
  }

  return {
    apiUrl,
    apiVer
  }
}

function SHARE_SERVICE(ambience) {
  let apiUrl, apiVer

  switch (ambience) {
    case "dev":
      // apiUrl = "http://localhost:8097/"
      apiUrl = "https://api-alessiplatform.codesour.dev/"
      apiVer = "v1"
      break;
    case "stage":
      apiUrl = "https://api-alessiplatform-stage.codesour.dev/"
      apiVer = "v1"
      break;
    case "prod":
      apiUrl = "https://api-alessiplatform-stage.codesour.dev/"
      apiVer = "v1"
      break;
    default:
  }

  return { apiUrl, apiVer }
}